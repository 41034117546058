<template>
  <breadcrumbs-container
    v-if="$route.meta.bread"
    :show-back="showBack"
  >
    <slot name="before" />

    <slot>
      <component
        :is="hasLink(b) ? 'a' : 'span'"
        v-for="(b, i) in $route.meta.bread"
        :key="`separator-${b.text}`"
        :href="hasLink(b) ? (b.href ?? Routing.generate(b.route, { _locale: spflocale })) : null"
        class="text-dark"
        :class="hasLink(b) ? 'text-underline' : ''"
      >
        <i
          v-if="i > 0"
          class="far fa-chevron-right px-2"
          style="line-height: normal"
        />
        <span
          :key="b.text"
          class=""
          style="gap: 5px"
          :class="i === $route.meta.bread.length - 1 ? 'font-weight-bold' : ''"
        >
          <i
            v-if="b.icon"
            :class="`fa fa-${b.icon}`"
          />
          <span
            style="white-space: pre; white-space: nowrap"
            class="overflow-hidden text-ellipsis"
          >
            {{ capitalize(trans(b.text)) }}</span
          >
        </span>
      </component>
    </slot>

    <slot name="after"></slot>
  </breadcrumbs-container>
</template>

<script>
import BreadcrumbsBackButton from './BreadcrumbsBackButton.vue';
import BreadcrumbsContainer from './BreadcrumbsContainer.vue';

export default {
  name: 'SfBreadcrumbs',
  components: { BreadcrumbsBackButton, BreadcrumbsContainer },
  props: {
    showBack: {
      type: Boolean,
      required: false,
      default: true,
    },
    firstBreadcrumbRoute: {
      type: String,
      required: false,
      default: undefined,
    },
    lastBreadcrumbRoute: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  computed: {
    hasRouter() {
      return !!this.$route;
    },
    Routing() {
      return Routing;
    },
    slug() {
      return window.SportFinder.store.center?.slug ?? null;
    },
  },
  methods: {
    hasLink(b) {
      return !!b.href || !!b.route;
    },
    generateRoute(b) {
      if (b.href) {
        return b.href;
      }
      return b.route ? Routing.generate(b.route, { _locale: this.spflocale }) : undefined;
    },
  },
};
</script>

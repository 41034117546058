<template>
  <form class="bg-white text-dark p-3 rounded-lg">
    <search-type v-model="type" />

    <div class="mb-3 border">
      <div class="border-bottom">
        <div class="pt-3 px-3 text-dark fs-2">
          <i class="far fa-search mr-1" />
          <span>Sport</span>
        </div>

        <search-what
          id="form_sport"
          :placeholder="trans('website.search.what')"
          css-class="border-0"
          class="px-1"
          @input="setWhat"
        />
      </div>

      <div class="border-bottom">
        <div class="pt-3 px-3 text-dark fs-2">
          <i class="far fa-location-dot mr-1" />
          <span>{{ capitalize(trans('general.place')) }}</span>
        </div>

        <!-- Country switcher -->
        <div class="d-flex">
          <div
            style="width: 5rem"
            class="d-inline-block"
          >
            <country-switcher />
          </div>
          <search-where
            id="form_where"
            placeholder="website.search.where"
            css-class="w-100"
            :country-code="countryCode"
            @input="setWhere"
          />
        </div>
      </div>

      <div>
        <label
          class="pt-3 px-3 text-dark fs-2"
          for="form_when"
        >
          <i class="far fa-calendar mr-1" />
          {{ trans('voc.date') }}
        </label>
        <search-when
          id="form_when"
          v-model="when"
          css-class="border-0"
          :placeholder="trans('website.search.when')"
        >
          <template #button-content>{{ null }}</template>
        </search-when>
      </div>
    </div>

    <button
      type="submit"
      class="form-control btn btn-orange btn-lg has-text-weight-bold"
      @click.prevent="submit"
    >
      {{ trans('website.search.search') }}
    </button>
  </form>
</template>

<script>
import CountrySwitcher from '../common/CountrySwitcher.vue';
import SearchType from '../map/SearchType.vue';
import SearchWhat from '../map/SearchWhat.vue';
import SearchWhen from '../map/SearchWhen.vue';
import SearchWhere from '../map/SearchWhere.vue';

export default {
  name: 'HomeSearch',
  components: {
    CountrySwitcher,
    SearchType,
    SearchWhat,
    SearchWhere,
    SearchWhen,
  },
  props: {
    countryCode: { type: String, required: true },
  },
  data() {
    return {
      type: 'center',
      what: null,
      where: null,
      when: null,
    };
  },
  methods: {
    setWhat(what) {
      this.what = what;
    },
    setWhere(where) {
      this.where = where;
    },
    setWhen(when) {
      this.when = when;
    },
    submit() {
      const query = {};
      let search = false;
      let q = '';

      if (this.what !== null) {
        search = true;
        query.what = this.what;
      }

      if (this.where !== null) {
        search = true;
        query.where = this.where.geocoding;
      }

      if (this.when !== null) {
        search = true;
        query.when = this.when;
      }

      if (search) {
        q = `?q=${encodeURIComponent(JSON.stringify(query))}`;
      }

      const url = `${this.$store.state[this.type === 'center' ? 'map' : 'match'].parameters.searchUrl}${q}`;

      document.location.href = url;
    },
  },
};
</script>

<template>
  <router-link
    v-if="backButtonTo && typeof backButtonTo === 'object'"
    v-slot="{ href }"
    custom
    :to="backButtonTo"
  >
    <a
      :href="href"
      class="d-flex align-items-center text-dark pl-2"
      style="text-decoration: none"
    >
      <i class="far fa-xl fa-chevron-left" />
      <span class="d-block pl-2">{{ trans($route.meta.bread.text) }}</span>
    </a>
  </router-link>
  <a
    v-else-if="typeof backButtonTo === 'string'"
    :href="backButtonTo"
    class="d-flex align-items-center text-dark pl-2"
    style="text-decoration: none"
  >
    <i class="far fa-xl fa-chevron-left" />
    <span class="d-block pl-2">{{ trans($route.meta.bread.text) }}</span>
  </a>
</template>

<script>
export default {
  name: 'BookingBreadcrumbsBackButton',
  computed: {
    backButtonTo() {
      const f = this.$route.meta.back;
      if (f && typeof f === 'function') {
        return f(this.$route);
      }

      return this.$route.meta.back
        ? Routing.generate(this.$route.meta.back, { _locale: this.spflocale })
        : undefined;
    },
  },
};
</script>

<template>
  <b-select
    :value="value"
    :disabled="!languages"
    :options="languages"
    :name="name"
    :required="required"
    @input="(v) => $emit('input', v)"
  />
</template>

<script>
import { capitalize } from '../../../helpers/typography';
import iso6391 from './iso639-1';

export default {
  name: 'LanguageType',
  props: {
    value: { type: String, required: false, default: undefined },
    name: { type: String, required: false, default: undefined },
    required: { type: Boolean, required: false, default: false },
  },
  emits: ['input'],
  computed: {
    languages() {
      const locales = Intl['DateTimeFormat'].supportedLocalesOf(iso6391).map((value) => ({
        value,
        text: capitalize(new Intl.DisplayNames([value], { type: 'language' }).of(value)),
      }));

      console.log(locales);

      return locales;
    },
  },
};
</script>

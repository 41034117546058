<template>
  <div>
    <breadcrumbs v-if="!hasCustomBreadCrumbs" />
    <router-view
      v-if="userLoaded"
      :key="$route.params.bookingId"
    >
      <slot />
    </router-view>
    <info-box />
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import InfoBox from '../../components/common/infobox/InfoBox.vue';
import Breadcrumbs from '../../components/common/Breadcrumbs/Breadcrumbs.vue';

export default {
  name: 'RootView',
  components: { Breadcrumbs, InfoBox },
  data() {
    return {
      userLoaded: !!this.user,
    };
  },
  computed: {
    ...mapFields('user', ['user']),
    hasCustomBreadCrumbs() {
      return this.$route.matched.some((r) => {
        return r.name === 'bookingPassThrought';
      });
    },
  },
  async created() {
    const id = this.$store.state.userId;

    if (id) {
      await this.$store.dispatch('user/loadUser', id);
    }

    this.userLoaded = true;
  },
};
</script>

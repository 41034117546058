<template>
  <div class="border-bottom">
    <div class="container-fluid p-2 p-sm-3 w-100">
      <div class="d-flex">
        <breadcrumbs-back-button v-if="showBack" />
        <div
          class="d-flex align-items-center justify-content-center overflow-hidden w-100 fs-2 fs-md-3"
          style="height: 1.5rem"
        >
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadcrumbsBackButton from './BreadcrumbsBackButton.vue';

export default {
  name: 'BreadcrumbsContainer',
  components: { BreadcrumbsBackButton },
  props: {
    showBack: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
};
</script>

/*
 * This router works on top on Symfony's one : if you reload the page you'll end up where you were
 * (full url), and this router also allows smooth transitions between routes without reload.
 */

import Vue from 'vue';
import VueRouter from 'vue-router';
import RootView from '../views/front/RootView.vue';
import PassThrought from '../views/PassThrought.vue';

Vue.use(VueRouter);

const memberHome = {
  text: 'general.profile',
  // to: { name: 'member' }
  route: 'member.home',
};

/** @var Array<RouteConfig> */
const routes = [
  {
    path: '/:lang',
    component: RootView,
    name: 'root',
    children: [
      {
        path: 'app',
        component: () => import('../views/front/Member/AppHome.vue'),
      },
      {
        path: 'member',
        component: () => import('../views/front/Member/MemberView.vue'),
        children: [
          {
            path: 'purchases',
            component: PassThrought,
            children: [
              {
                path: 'archive',
                name: 'memberPurchasesArchive',
                component: () => import('../views/front/Member/BookingsArchiveView.vue'),
                meta: {
                  bread: [
                    memberHome,
                    {
                      text: 'general.bookings',
                      route: 'member.purchases',
                    },
                    {
                      text: 'Archive',
                    },
                  ],
                  back: 'member.purchases',
                },
              },
              {
                path: '',
                name: 'memberPurchases',
                component: () => import('../views/front/Member/BookingsView.vue'),
              },
            ],
          },
          {
            path: 'favorites',
            name: 'memberFavorites',
            component: () => import('../views/front/Member/FavoritesView.vue'),
            meta: {
              bread: [
                memberHome,
                {
                  text: 'general.favorites',
                },
              ],
              back: 'member.home',
            },
          },
          {
            path: 'my_wallets',
            name: 'memberWallets',
            component: () => import('../views/front/Member/WalletsView.vue'),
            meta: {
              bread: [
                memberHome,
                {
                  text: 'general.wallets',
                },
              ],
              back: 'member.home',
            },
          },
        ],
      },
      {
        path: 'matches/calendar/:q(.*)',
        name: 'matchesCalendar',
        component: () => import('../views/front/Matches/MatchesCalendarView.vue'),
      },
      {
        path: 'matches/map/:q(.*)',
        name: 'matchesMap',
        component: () => import('../views/front/Matches/MatchesMapView.vue'),
      },
      {
        path: 'matches/list/:q(.*)',
        name: 'matches',
        component: () => import('../views/front/Matches/MatchesView.vue'),
      },
      {
        path: 'center/:centerId',
        name: 'center',
        component: () => import('../views/front/Center/CenterView.vue'),
        children: [
          {
            path: 'booking/:bookingType/:bookingProductId',
            name: 'bookBy',
            component: () => import('../views/front/Center/BookByView.vue'),
            children: [
              {
                path: 'configure',
                name: 'configureBookingOld',
                component: () => import('../views/front/Center/ConfigureBookingOld.vue'),
              },
              {
                path: 'book',
                name: 'FieldRentalBookingCalendar',
                component: () => import('../views/front/Center/FieldRentalBookingCalendarView.vue'),
              },
            ],
          },
        ],
      },
      {
        path: 'booking/:bookingId',
        name: 'bookingPassThrought',
        component: () => import('../views/front/Purchase/BookingView.vue'),
        children: [
          {
            path: 'configure',
            name: 'bookingConfigure',
            component: () => import('../views/front/Purchase/BookingConfigureView.vue'),
            meta: { showBookingSummary: true, bread: [{ text: 'booking.configure.bread' }] },
          },
          {
            path: 'select_option',
            name: 'bookingSelectOptions',
            component: () => import('../views/front/Purchase/BookingSelectOptionsView.vue'),
            meta: { showBookingSummary: true, bread: [{ text: 'booking.options.bread' }] },
          },
          {
            path: 'select_payment',
            name: 'bookingSplitPayment',
            component: () => import('../views/front/Purchase/BookingSplitPaymentView.vue'),
            meta: { showBookingSummary: true, bread: [{ text: 'booking.splitPayment.bread' }] },
          },
          { path: 'pay', name: 'bookingOldPayment' },
          {
            path: 'payment',
            component: PassThrought,
            children: [
              {
                path: 'success',
                name: 'bookingPayIntentSuccess',
                component: () => import('../views/front/Purchase/BookingPaymentResponseView.vue'),
                meta: {
                  showBookingSummary: false,
                  bread: [{ text: 'booking.paymentResponse.bread' }],
                },
              },
              {
                path: 'fail',
                name: 'bookingPayIntentFailure',
                component: () => import('../views/front/Purchase/BookingPaymentResponseView.vue'),
                meta: {
                  showBookingSummary: false,
                  bread: [{ text: 'booking.paymentResponse.bread' }],
                },
              },
              {
                path: ':intent',
                name: 'bookingPayIntent',
                component: () => import('../views/front/Purchase/BookingSelectPaymentView.vue'),
                meta: {
                  showBookingSummary: false,
                  bread: [{ text: 'booking.payment.card.bread' }],
                },
              },
              {
                path: '',
                name: 'bookingPay',
                component: () => import('../views/front/Purchase/BookingSelectPaymentView.vue'),
                meta: { showBookingSummary: false, bread: [{ text: 'booking.payment.bread' }] },
              },
            ],
          },
          {
            path: 'confirmation',
            name: 'bookingConfirmation',
            component: () => import('../views/front/Purchase/BookingConfirmationView.vue'),
            meta: {
              showBookingSummary: false,
              bread: {
                text: 'booking.confirmation.bread',
                layout: 'back',
                back: ($route) => ({
                  name: 'booking',
                  params: { bookingId: $route.params.bookingId },
                }),
              },
            },
          },
          {
            path: 'tickets',
            name: 'bookingConfirmationTickets',
            component: () => import('../views/front/Purchase/BookingConfirmationTicketsView.vue'),
            meta: {
              showBookingSummary: false,
              bread: {
                text: 'booking.accessCode.bread',
                layout: 'back',
                back: ($route) => ({
                  name: 'booking',
                  params: { bookingId: $route.params.bookingId },
                }),
              },
            },
          },
          {
            path: '',
            name: 'booking',
            component: () => import('../views/front/Purchase/BookingDefaultView.vue'),
            meta: { showBookingSummary: true },
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  // base: 'fr',
  // base: process.env.BASE_URL,
  routes,
});

export default router;

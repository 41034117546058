<template>
  <b-form-group
    v-slot="{ ariaDescribedby }"
    label-size="sm"
    label-class="p-0"
    content-cols="12"
    class="mb-1 align-items-center text-capitalize"
  >
    <b-form-radio-group
      :v-model="value"
      :checked="value"
      size="sm"
      :options="options"
      :aria-describedby="ariaDescribedby"
      @input="$emit('input', $event)"
    />
  </b-form-group>
</template>

<script>
export default {
  name: 'SearchType',
  props: {
    value: { type: String, required: false, default: null },
  },
  emits: ['input'],
  computed: {
    options() {
      const isL = !!this.$store.state.userId;
      return [
        { text: this.trans('general.place'), value: 'center' },
        { text: `match${isL ? '' : ' (login)'}`, value: 'match', disabled: !isL },
      ];
    },
  },
};
</script>

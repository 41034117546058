<template>
  <b-select
    v-model="value"
    class="border-0 no-shadow"
    :options="countries"
  />
</template>

<script>
export default {
  name: 'CountrySwitcher',
  computed: {
    countries() {
      return [
        {
          text: '🇧🇪',
          value: 'BE',
        },
        {
          text: '🇫🇷',
          value: 'FR',
        },
      ];
    },
    value: {
      get() {
        return window.SportFinder.country;
      },
      set(v) {
        if (v === 'FR' && this.value === 'BE') {
          window.location.assign(
            Routing.generate('website.homepage', { _locale: this.spflocale, countrySlug: 'france' })
          );
        } else if (v === 'BE' && this.value === 'FR') {
          window.location.assign(
            Routing.generate('website.homepage', {
              _locale: this.spflocale,
              countrySlug: 'belgium',
            })
          );
        } else {
          throw new Error('Value not understood');
        }
      },
    },
  },
};
</script>
